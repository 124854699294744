<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="font-weight-bolder">
            <span class="align-middle">Add new Assessment to vendor</span>
          </h2>
          <p class="card-subtitle text-muted pt-75">
            Fill the form and click submit to start a new assessment.
          </p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body pt-3" v-if="questionnaire !== null">
        <validation-observer ref="tpr_assessment_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick()">
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="title"
                name="Name"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input
                  v-model="formData.title"
                  id="title"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Description">
              <validation-provider
                #default="{ errors }"
                vid="description"
                name="Description"
              >
                <b-form-input
                  v-model="formData.description"
                  id="description"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="User">
              <validation-provider
                #default="{ errors }"
                vid="user_id"
                name="User"
                :rules="{ required: true }"
              >
                <b-form-select
                  v-model="formData.user.id"
                  :options="formData.user.options"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Vendors">
              <vue-autosuggest
                :suggestions="formData.vendors.suggestions"
                :limit="5"
                v-model="vendorFilters.name"
                id="autosuggest__input"
                :input-props="formData.vendors.vendorInputProps"
                @input="getVendorsBySearchPhraseAndSetOptions"
                @selected="onVendorSelected"
                :get-suggestion-value="getVendorSuggestionValue"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}"
                    >{{ suggestion.item.name }}
                  </span>
                </template>
              </vue-autosuggest>
              <div
                class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"
              >
                <b-badge
                  class="mr-25 mt-25"
                  v-for="vendor in formData.vendors.selectedVendors"
                  variant="light-primary"
                  :key="vendor._id"
                  >{{ vendor.name
                  }}<feather-icon
                    @click="handleVendorRemoveAction(vendor._id)"
                    class="text-danger ml-25 cursor-pointer"
                    icon="XIcon"
                /></b-badge>
              </div>
            </b-form-group>

            <b-form-group label="Questionnaire">
              <div
                class="w-100 mb-1 d-flex flex-column align-items-start justify-content-center p-1 rounded border"
                style="min-height: 100px"
              >
                <template v-for="item in formData.selectedQuestionnaire">
                  <b-badge
                    :key="item._id"
                    variant="light-primary"
                    style="position: relative"
                    class="d-flex flex-direction-column align-items-center justify-content-between my-25 cursor-pointer"
                    v-b-tooltip.hover
                    :title="item.title"
                  >
                    <p class="truncate text-left mb-0">
                      {{ item.title }}
                    </p>
                    <div
                      @click="handleQuestionnaireRemoveClick(item._id)"
                      style="width: 27px"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="ml-75 text-danger"
                        size="27"
                      />
                    </div>
                  </b-badge>
                </template>
              </div>
              <add-or-remove-list
                title="Add Questionnaire"
                :buttonAddType="true"
                :buttonFunction="handleAddButtonClick"
                :searchFunction="handleSearchFunction"
                :items="questionnaire"
                :allowTooltip="true"
                :returnTypeIsObject="true"
              />
            </b-form-group>

            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                type="submit"
                variant="primary"
                class="mt-1 w-25"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BBadge,
  VBHover,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";

import UtilsMixins from "../../../mixins/UtilsMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import AddOrRemoveList from "../../../components/AddOrRemoveList.vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    AddOrRemoveList,
    VueAutosuggest,
    VBHover,
    VBTooltip,
  },
  directives: {
    "b-hover": VBHover,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      questionnaire: null,
      filters: {
        search: null,
        dont_paginate: true,
      },
      vendorFilters: {
        name: null,
      },
      formData: {
        title: null,
        description: null,
        vendors: {
          suggestions: [],
          selectedVendors: [],
          vendorInputProps: {
            class: "form-control",
            placeholder: "Search vendors..",
          },
        },
        selectedQuestionnaire: [],
      },
    };
  },
  mixins: [UtilsMixins, ThirdPartyRisksMixins, ResponseMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.load();
  },

  watch: {
    filters: {
      handler: function (newValue) {
        this.getQuestionnaireAndSetData(newValue);
      },
      deep: true,
    },
    vendorFilters: {
      handler: function (newValue) {
        this.getVendorsBySearchPhraseAndSetOptions(newValue);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getQuestionnaireAndSetData(this.filters);
    },

    handleSubmitClick() {
      this.showOverlay = true;
      console.log(this.formData);
      this.createTPRAssessment(this.formData)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.reloadParent();
          this.closeSidebar();
        });
    },
    getQuestionnaireAndSetData(params) {
      this.showOverlay = true;
      this.getImportedQuestionnaires(params)
        .then((res) => {
          this.questionnaire = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getVendorsBySearchPhraseAndSetOptions(params) {
      this.debounceFunction(() => {
        this.getVendors(params)
          .then((res) => {
            this.formData.vendors.suggestions.push({
              name: "vendors",
              data: res.data.data.data,
            });
          })
          .catch((err) => {
            this.handleError(err);
          });
      });
    },
    onVendorSelected(value) {
      this.formData.vendors.selectedVendors.push(value.item);
    },
    getVendorSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    handleVendorRemoveAction(id) {
      let newSelectedVendors = this.formData.vendors.selectedVendors.filter(
        (vendor) => vendor._id !== id
      );
      this.formData.vendors.selectedVendors = newSelectedVendors;
    },

    handleSearchFunction(searchPhrase) {
      this.filters.search = searchPhrase;
    },
    handleAddButtonClick(questionnaire) {
      if (this.formData.selectedQuestionnaire.length > 0) {
        const questionnaireIndex =
          this.formData.selectedQuestionnaire.findIndex(
            (q) => q._id === questionnaire._id
          );
        if (questionnaireIndex == -1) {
          this.formData.selectedQuestionnaire.push(questionnaire);
        }
      } else {
        this.formData.selectedQuestionnaire.push(questionnaire);
      }
    },
    handleQuestionnaireRemoveClick(id) {
      let newQuestionnaire = this.formData.selectedQuestionnaire.filter(
        (q) => q._id !== id
      );
      this.formData.selectedQuestionnaire = newQuestionnaire;
    },
  },
};
</script>

<style lang="css" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
}
</style>